import { NetworkId, SupportedWallet, WalletId, WalletManagerConfig } from '@txnlab/use-wallet-react'
import { ALGORAND_NETWORK, CLIENT_BASE_URL, isLocalnet } from 'src/data/constants'
import { WC_DESCRIPTION, WC_NAME, WC_PROJECT_ID } from 'src/data/walletconnect'
import type { AlgoNetwork } from 'types/api'

const providers: SupportedWallet[] = [
  { id: WalletId.PERA, options: { shouldShowSignTxnToast: false } },
  { id: WalletId.DEFLY, options: { shouldShowSignTxnToast: false } },
  {
    id: WalletId.WALLETCONNECT,
    options: {
      projectId: WC_PROJECT_ID,
      metadata: {
        name: WC_NAME,
        description: WC_DESCRIPTION,
        url: CLIENT_BASE_URL,
        icons: [`${CLIENT_BASE_URL}/img/nfd.png`]
      },
      themeMode: 'light',
      explorerRecommendedWalletIds: [
        '5864e2ced7c293ed18ac35e0db085c09ed567d67346ccb6f58a0327a75137489'
      ]
    }
  },
  WalletId.EXODUS,
  { id: WalletId.LUTE, options: { siteName: 'NFDomains' } },
  WalletId.KIBISIS
]

const devProviders: SupportedWallet[] = [
  {
    id: WalletId.KMD,
    options: {
      token: process.env.NEXT_PUBLIC_KMD_TOKEN || '',
      baseServer: process.env.NEXT_PUBLIC_KMD_HOST || '',
      port: process.env.NEXT_PUBLIC_KMD_PORT || '',
      wallet: process.env.NEXT_PUBLIC_KMD_WALLET || ''
    }
  },
  { id: WalletId.LUTE, options: { siteName: 'NFDomains' } }
]

const supportsNetwork = {
  betanet: [WalletId.KIBISIS, WalletId.LUTE, WalletId.WALLETCONNECT],
  testnet: [WalletId.DEFLY, WalletId.KIBISIS, WalletId.LUTE, WalletId.PERA, WalletId.WALLETCONNECT],
  mainnet: [WalletId.DEFLY, WalletId.EXODUS, WalletId.LUTE, WalletId.PERA, WalletId.WALLETCONNECT]
}

const getSupportedProviders = (env: string | undefined): SupportedWallet[] => {
  const network = env as AlgoNetwork
  if (!network || Object.keys(supportsNetwork).indexOf(network) === -1) {
    return providers
  }
  return providers.filter((providerDef) => {
    if (typeof providerDef === 'string') {
      return supportsNetwork[network].includes(providerDef)
    } else {
      return supportsNetwork[network].includes(providerDef.id)
    }
  })
}

const configuredProviders = isLocalnet ? devProviders : getSupportedProviders(ALGORAND_NETWORK)

function parseNetworkId(input: string | undefined): NetworkId | undefined {
  if (!input) {
    return undefined
  }

  return Object.values(NetworkId).includes(input as NetworkId) ? (input as NetworkId) : undefined
}

export const walletManagerConfig: WalletManagerConfig = {
  wallets: configuredProviders,
  network: parseNetworkId(ALGORAND_NETWORK),
  algod: {
    token: process.env.NEXT_PUBLIC_NODE_TOKEN || '',
    baseServer: process.env.NEXT_PUBLIC_NODE_URL || '',
    port: process.env.NEXT_PUBLIC_NODE_PORT || ''
  }
}
